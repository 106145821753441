.sidenav-root {
  width: 320px;
}
.sidenav__header {
  display: flex;
  align-items: center;
  min-height: 56px;
  padding: 0 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.7);
}
@media (min-width: 600px) {
  .sidenav__header {
    min-height: 64px;
  }
}
